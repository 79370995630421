import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContainer from "../components/block-container"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <BlockContainer>
      <h1>404</h1>
      <p>We can't seem to find the page you're looking for.</p>
    </BlockContainer>
  </Layout>
)

export default NotFoundPage
